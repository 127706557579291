import axios, {AxiosResponse} from 'axios';
import {AppLocator} from '../../../app-locator';
import {sleep} from '../../../shared/utils';
import {Media} from './media.types';
import {getAuth} from '../auth';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const samples = [
  {
    id: 2,
    pub_id: '',
    name: '',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/landscape-sample.jpg',
  },
  {
    id: 3,
    pub_id: '',
    name: '',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/square-sample.png',
  },
  {
    id: 0,
    pub_id: '',
    name: 'Landscape image',
    // name: 'Landscape image with nature background and what shall me name it',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/2.jpg',
  },
  {
    id: 0,
    pub_id: '',
    name: 'Landscape image',
    // name: 'Landscape image with nature background and what shall me name it',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/2.jpg',
  },
  {
    id: 0,
    pub_id: '',
    name: 'Landscape image',
    // name: 'Landscape image with nature background and what shall me name it',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/2.jpg',
  },
  {
    id: 0,
    pub_id: '',
    name: 'Landscape image',
    // name: 'Landscape image with nature background and what shall me name it',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/2.jpg',
  },
  {
    id: 0,
    pub_id: '',
    name: 'Landscape image',
    // name: 'Landscape image with nature background and what shall me name it',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/2.jpg',
  },
  {
    id: 0,
    pub_id: '',
    name: 'Landscape image',
    // name: 'Landscape image with nature background and what shall me name it',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/2.jpg',
  },
  {
    id: 0,
    pub_id: '',
    name: 'Landscape image',
    // name: 'Landscape image with nature background and what shall me name it',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/2.jpg',
  },
  {
    id: 0,
    pub_id: '',
    name: 'Landscape image',
    // name: 'Landscape image with nature background and what shall me name it',
    create_date: new Date(),
    created_by: {
      id: 0,
      name: '',
    },
    status: '',
    thumbnail: '/media/videos/thumb/2.jpg',
  },
];

export const getMedias = async (query: string) => {
  const request = await axios
    .get(`${API_URL}/videos/view?${query}`)

    .then((d: AxiosResponse<any>) => {
      const data = d.data;
      return data as GetVideoAPIResponse;
    });
  return {data: {videos: request.video, count: request.count}};
};

export const removeImageBackground = async (
  data: any,
  setProgress?: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append('resolution', data.resolution);
  formData.append('image', data.image);

  const res = await axios({
    method: 'POST',
    url: `${API_URL}/videos/remove_background`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
    responseType: 'blob',
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 100;

      if (setProgress) setProgress(Math.round(progress));
    },
  });
  console.log('removeImageBackground', res);
  const file = new File([res.data], 'cleared_product_image.png', {type: res.data.type});

  return file;
};

export const generateMedia = async (video: Media, setProgress?: (progress: number) => void) => {
  const formData = new FormData();
  formData.append('description', video.prompt);
  formData.append('auto_enhance', video.enhancePrompt ? '1' : '0');
  formData.append('inspire_me', video.prompt ? '0' : '1');
  formData.append('resolution', video.resolution);
  //   formData.append('original_product_image ', video.productImage || '');
  //   formData.append('cleared_product_image ', video.transparentProductImage || '');
  //   formData.append('product_in_frame_image', video.productWithFrameImage || '');
  formData.append('files', video.productImage || '');
  formData.append('files', video.transparentProductImage || '');
  formData.append('files', video.productWithFrameImage || '');

  const res = await axios({
    method: 'POST',
    url: `${API_URL}/videos/create`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 100;

      if (setProgress) setProgress(Math.round(progress));
    },
  });

  return res;
};

export type GetVideoAPIResponse = {
  count: number;
  video: Media[];
};
export const getSingleMedia = async (pubId: string) => {
  return axios
    .get(`${API_URL}/videos/view?filter_video_pub_id=${pubId}`)

    .then((d: AxiosResponse<any>) => {
      const data = d.data;
      return data as GetVideoAPIResponse;
    });
};

export const getMediaItemsByIds = async (pubIds: string[]) => {
  return axios
    .post(`${API_URL}/videos/view_pub_ids?`, {
      videos_ids: pubIds,
    })

    .then((d: AxiosResponse<any>) => {
      const data = d.data;
      return data as GetVideoAPIResponse;
    });
};

export const deleteMedia = async (pub_id: string) => {
  return await axios.put(`${API_URL}/videos/delete/${pub_id}`);
};

export const selectSceneImage = async (
  sceneImagePubId: string,
  videoPubId: string,
  dynamicsDescription: string,
  image: File
) => {
  const formData = new FormData();
  formData.append('scene_image_pub_id', sceneImagePubId);
  formData.append('dynamics_description', dynamicsDescription);
  formData.append('image', image);

  const res = await axios({
    method: 'PUT',
    url: `${API_URL}/videos/select_scene_image/${videoPubId}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
  });

  return res;
};

export const regenerateScenes = async (videoPubId: string, sceneImagePubId?: string) => {
  const res = await axios({
    method: 'PUT',
    url: `${API_URL}/videos/regenerate_scene_images/${videoPubId}`,
    data: sceneImagePubId ? {scene_image_pub_id: sceneImagePubId} : {},
    headers: {
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
  });

  return res;
};

export const editMedia = async (media: Media) => {
  const formData = new FormData();
  formData.append('name', media.name);
  formData.append('video_pub_id', media.pub_id);

  const res = await axios({
    method: 'PUT',
    url: `${API_URL}/videos/edit`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
  });

  return res;
};

export const regenerateVideo = async (pubId: string) => {
  return axios
    .post(`${API_URL}/regenrate_video/${pubId}`, {})

    .then((d: AxiosResponse<any>) => {
      const data = d.data;
      return data;
    });
};
