/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {convertSecondsToReadableTime} from '../../../../../../shared/date-util';
import clsx from 'clsx';
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import {Dialog} from 'primereact/dialog';
import {useAuth} from '../../../../auth';
import {Link, useNavigate} from 'react-router-dom';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import {
  deepEqual,
  downloadVideo,
  getRandomString,
  parseError,
} from '../../../../../../shared/utils';
import {confirmDialog} from 'primereact/confirmdialog';
import {toast} from 'react-toastify';

import {useCardsSelection} from '../../../../models-management/pages/models-list/core/CardsSelectionProvider';
import HasPermission from '../../../../auth/HasPermission';
import {deleteDialogProps} from '../../../../shared/types';
import {deleteTemplate, deleteVideo} from '../../../../videos/vidoes.api';
import {Video, VideoStatusEnum} from '../../../../videos/vidoes.types';
import {Media, MediaStatusEnum} from '../../../media.types';
import {sleep} from 'react-query/types/core/utils';
import {deleteMedia, getSingleMedia, regenerateVideo} from '../../../media.api';
import {useMediaLibraryQuery} from '../MediaLibraryQueryProvider';
import {ModalLayout} from '../../../../shared/components/ModalLayout';
import {MediaInformationWindow} from './MediaInformationWindow';
import './MediaCard.scss';
import {MediaEditWindow} from './MediaEditWindow';
dayjs.extend(relativeTime as any);

interface Props {
  video: Media;
  isFocused?: boolean;
  isTemplate?: boolean;
  onUpdate?: () => void;
}

export const MediaCard = ({video, onUpdate}: Props) => {
  const [media, setMedia] = useState(video);
  const [showVideo, setShowVideo] = useState(false);
  const [showWindow, setShowWindow] = useState(false);
  const [showEditWindow, setShowEditWindow] = useState(false);
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const {select, unSelect, checkSelected} = useCardsSelection();
  const selected = checkSelected(media.pub_id + '');
  const intervalRef = useRef<number | null>(null); // Use useRef to store the interval ID
  const {isLoading, refetch: refreshLibrary, response, query} = useMediaLibraryQuery();
  const [regenerating, setRegenerating] = useState(false);

  const deleteBtnClicked = () => {
    confirmDialog({
      ...deleteDialogProps,
      message: `Are you sure you want to delete?`,
      accept: () => {
        submitDelete();
      },
      reject: () => {},
    });
  };

  const submitDelete = async () => {
    if (!video) return;

    try {
      setDeleting(true);

      await deleteMedia(media.pub_id);

      toast.success('Video deleted successfully!');

      refreshLibrary();

      onUpdate && onUpdate();
    } catch (error) {
      setDeleting(false);
      alert(parseError(error));
    }
    setDeleting(false);
  };

  const download = async () => {
    if (media.status !== MediaStatusEnum.COMPLETED) return;
    try {
      await downloadVideo({
        videoUrl: media.video_url,
        fileName: media.name || 'Vree Generative Media',
        onProgress,
      });
    } catch (error: any) {
      toast.error('Download failed, please contact support!');
    }
  };
  const onProgress = (progressEvent: ProgressEvent) => {
    const total = progressEvent.total ?? 0;
    const current = progressEvent.loaded;
    const percentCompleted = Math.round((current / total) * 100);
    setDownloadProgress(percentCompleted);
  };

  const refetchModel = async () => {
    if (!media.pub_id) return;

    console.log('Refetching processing model');

    try {
      const res = await getSingleMedia(media.pub_id);
      const updatedMedia = res.video[0];

      if ([MediaStatusEnum.COMPLETED, MediaStatusEnum.FAILED].includes(updatedMedia.status))
        setMedia(updatedMedia);
    } catch (error) {
      console.error(`Error while refreshing single media, (details: ${parseError(error)}`);
    }
  };

  const startInterval = () => {
    if (intervalRef.current === null) {
      console.log('startInterval');
      intervalRef.current = window.setInterval(() => {
        refetchModel();
      }, 10 * 1000);
    }
  };

  const stopInterval = () => {
    if (intervalRef.current !== null) {
      console.log('stopInterval');
      clearInterval(intervalRef.current);
      intervalRef.current = null; // Reset ref to avoid multiple intervals
    }
  };

  const regenerate = async () => {
    if (!media.pub_id) return;

    try {
      setRegenerating(true);
      const res = await regenerateVideo(media.pub_id);

      const newPubId = res?.pub_id;
      setRegenerating(false);

      if (!newPubId) return;

      navigate(`/rich-media/video/${newPubId}`);
    } catch (error) {
      setRegenerating(false);

      console.error(`Error while regenerating, details: ${parseError(error)}`);
      toast.error(`Error while regenerating: ${parseError(error)}`);
    }
  };

  useEffect(() => {
    const processing = [MediaStatusEnum.PROCESSING].includes(media.status);

    processing ? startInterval() : stopInterval();
  }, [media]);

  useEffect(() => {
    // if (deepEqual(video, media)) return;

    setMedia(video);
  }, [video]);

  useEffect(() => {
    return () => {
      stopInterval();
    };
  }, []);

  return (
    <article
      key={media.pub_id}
      onBlur={() => setShowVideo(false)}
      className={clsx(
        'card card-bordered card-flush shadow-sm hover-container',
        {
          'border-primary border-1 shadow-primary shadow-sm': selected,
        },
        {faded: [MediaStatusEnum.DRAFT, MediaStatusEnum.PROCESSING].includes(video.status)}
      )}
    >
      <header className='position-absolute top-0 left-0 right-0 gradient-background-hover text-white  d-flex justify-content-between align-items-center  p-4 py-4 rounded-top z-3'>
        <div>
          <div className=' fs-6 fw-semibold capitalized text-shadow'>{media.name || ''}</div>
        </div>
        <div className='d-flex  align-items-center '>
          {!showVideo && (
            <>
              {/* // <a
            //   title='Hide Video'
            //   className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center'
            //   onClick={(e) => {
              //     e.stopPropagation();
            //     setShowVideo(false);
            //   }}
            // >
            //   <i className='ki-outline ki-cross-square text-white fs-1'></i>
            // </a> */}

              <a
                title='Edit name'
                className='visible-on-hover cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEditWindow(true);
                }}
              >
                <i className='ki-outline ki-pencil text-white fs-3'></i>
              </a>

              <a
                title='Info'
                className='visible-on-hover cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowInfoWindow(true);
                }}
              >
                <i className='ki-outline ki-information-4 text-white fs-3'></i>
              </a>

              {!selected && media.status === MediaStatusEnum.COMPLETED && (
                <a
                  title='Select'
                  className=' cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center'
                  onClick={(e) => {
                    e.stopPropagation();
                    select(media.pub_id + '');
                  }}
                >
                  <i className='ki-outline ki-mouse-circle text-white fs-3'></i>
                </a>
              )}

              {selected && (
                <a
                  title='Unselect'
                  className='cursor-pointer w-25px h-25px rounded-circle bg-primary  d-flex flex-center'
                  onClick={(e) => {
                    e.stopPropagation();
                    unSelect(media.pub_id + '');
                  }}
                >
                  <i className='ki-solid ki-check-circle text-white  fs-2'></i>
                </a>
              )}
            </>
          )}
        </div>
      </header>

      <div
        onClick={async () => {
          if (media.status !== MediaStatusEnum.COMPLETED) return;

          setShowVideo(true);
          setTimeout(() => {
            document.getElementsByTagName('video')[0]?.focus();
          }, 400);
        }}
        className='video-thumbnail-container aspect-ratio-box min-h-200px bg-light  rounded z-2'
        style={{
          backgroundImage: `url('${
            media.thumbnail_url || media.reduced_original_product_image_url
          }')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          filter: media.status === MediaStatusEnum.DRAFT ? '' : showVideo ? 'blur(2px)' : '',
        }}
      ></div>

      <div className='z-3'>
        <>
          {media.status === MediaStatusEnum.PROCESSING && (
            <div className=''>
              <div className='  center-center  text-white'>
                <div className='loader w-20px h-20px'></div>
              </div>
              <div className='text-white text-sm text-center mt-9 center-center w-100 px-2 text-shadow '>
                Processing...
              </div>
            </div>
          )}
          {/* {media.status === MediaStatusEnum.FAILED && (
            <div className=''>
              <div
                className='badge  center-center bg-white text-danger'
                title={media.reason}
                onClick={() => toast.warning('Failure details: ' + media.reason)}
              >
                Failed
              </div>
            </div>
          )} */}
          {media.status === MediaStatusEnum.DRAFT && (
            <div
              className='cursor-pointer'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/rich-media/video/${media.pub_id}`);
              }}
            >
              <div className='badge  center-center bg-white text-black'>Draft</div>
              <div className='text-white text-sm text-center mt-7 center-center w-100 px-4 py-7 text-shadow '>
                Click to continue
              </div>
            </div>
          )}
        </>
      </div>

      {showVideo && (
        <div className='aspect-ratio-box position-absolute top-0 z-far'>
          <video
            id={media.id + ''}
            poster={media.thumbnail_url || media.original_product_image_url}
            className='w-100 rounded  aspect-ratio-box-content '
            autoPlay
            controls
            controlsList='nodownload'
            muted
            onEnded={() => setShowVideo((v) => !v)}
          >
            <source src={media.video_url} type='video/mp4' />
          </video>
        </div>
      )}

      {!showVideo && (
        <footer className=' position-absolute bottom-0 left-0 right-0 gradient-background-reverse-hover text-white z-3 d-flex justify-content-between align-items-center  p-4 py-4 rounded-bottom '>
          <div className=' '>
            <div>
              {media.is_deleted && <span className=' badge badge-danger'>Deleted</span>}

              {!media.is_deleted && media.status === MediaStatusEnum.FAILED && (
                <span
                  className=' badge badge-light-danger cursor-pointer'
                  title={media.reason}
                  onClick={() => toast.warning('Failure details: ' + media.reason)}
                >
                  Failed
                </span>
              )}
            </div>
          </div>
          <div className='d-flex align-items-center visible-on-hover'>
            {downloadProgress > 0 && downloadProgress < 100 && (
              <div className=''>({downloadProgress}%)</div>
            )}

            {media.status === MediaStatusEnum.DRAFT && (
              <a
                title='Edit'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/rich-media/video/${media.pub_id}`);
                }}
              >
                <i className='ki-outline ki-notepad-edit text-white fs-3'></i>
              </a>
            )}

            <HasPermission code='medias.download'>
              {media.status === MediaStatusEnum.COMPLETED && (
                <a
                  title='Download'
                  className='cursor-pointer w-30px h-30px rounded-circle bg-hover-info  d-flex flex-center my-1'
                  onClick={(e) => {
                    e.stopPropagation();
                    download();
                  }}
                >
                  {!(downloadProgress > 0 && downloadProgress < 100) && (
                    <i className='ki-outline ki-cloud-download text-white fs-2'></i>
                  )}
                  {downloadProgress > 0 && downloadProgress < 100 && (
                    <div className='position-relative'>
                      <i className='ki-outline ki-loading text-white fa fa-spin fs-2'></i>
                    </div>
                  )}
                </a>
              )}
            </HasPermission>

            {/* 
            <HasPermission code='medias.share'>
            <a
              title='Share'
              className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
              onClick={(e) => {
                e.stopPropagation();
                setShowShareWindow(true);
              }}
            >
              <i className='bi bi-share text-white fs-3'></i>
            </a>
            </HasPermission>
             */}

            <HasPermission code='medias.delete'>
              <a
                title='Delete'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-danger  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  deleteBtnClicked();
                }}
              >
                <i className='ki-outline ki-trash text-white fs-3'></i>
              </a>
            </HasPermission>

            <a
              title='Regenerate'
              className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
              onClick={(e) => {
                e.stopPropagation();
                regenerate();
              }}
            >
              {!regenerating && (
                <i className='ki-outline ki-arrow-circle-right text-white fs-2'></i>
              )}
              {regenerating && (
                <div className='position-relative'>
                  <i className='ki-outline ki-loading text-white fa fa-spin fs-2'></i>
                </div>
              )}
            </a>
          </div>
        </footer>
      )}

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showInfoWindow}
        onHide={() => setShowInfoWindow(false)}
      >
        <ModalLayout
          onClose={() => setShowInfoWindow(false)}
          showHeader
          showFooter
          showCloseButton
          title={`Information`}
        >
          <MediaInformationWindow video={video} />
        </ModalLayout>
      </Dialog>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showEditWindow}
        onHide={() => setShowEditWindow(false)}
      >
        <MediaEditWindow
          submitComplete={(updatedMedia) => {
            setShowEditWindow(false);

            if (updatedMedia) setMedia(updatedMedia); // optimistic update
          }}
          video={video}
        />
      </Dialog>
    </article>
  );
};
